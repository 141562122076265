import React, { useState } from 'react';
import TabTitle from '../components/meta/tabTitle';
import Layout from '../components/layout/Layout';
import GenericPageTopHeader from '../components/GenericPageTopHeader';
import { stringifyUrl } from 'query-string';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { navigate } from 'gatsby';

const ContactPage = () => {
    const
    {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm()

    const onSubmit = data => {
        let formData =  new URLSearchParams();
        for (const key in data)
        {
            formData.set(key.replace('_', '.'), data[key]);
        }

        const deploymentUrl='https://script.google.com/macros/s/AKfycbxqVzHntRmiZFcEYeKNxj0tCdnadjJhPVwGVyr3-5bYTK6EJ6oEchI6xYO8hfsCtoKY-Q/exec';
        
        fetch(deploymentUrl, {
            method: 'POST',
            cache: 'no-cache',
            body: formData
        }).then(response =>
            {
                if (!response.ok)
                {
                    console.log('Failure', response);
                    setError('apiError', `${response.statusText} (code ${response.status})`);
                }
                else
                {
                    const contentType = response.headers.get('Content-Type');
                    if (/application\/json/.test(contentType))
                    {
                        response.json().then(json =>
                            {
                                if (json.result === 'success')
                                {
                                    navigate('/contact-thank-you');
                                }
                                else
                                {
                                    console.log('JSON Error', json);
                                    setError('apiError', { message: `${json.error}` })
                                }
                            })
                    }
                    else
                    {
                        console.log('Error: non-JSON return', response);
                        setError('apiError', { message: `${response}` })
                    }
                }
            }
        ).catch(error =>
            {
                console.log('Error', error);
                setError('apiError', { message: `${error}` })
            }
        )
    }

  return (
    <Layout>
      <TabTitle title="Contact" />
      <GenericPageTopHeader pageTitle='Contact' className='container mx-auto my-4 text-4xl' />
      <section className="container mx-auto">
          <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-wrap -mx-3 mb-6">
              <input className="honey-pot-for-bad-bears"
                     id="entry_976012278"
                     type="text"
                     size="20"
                     maxLength="255"
                     {...register("entry_976012278", { required: false })}
              />
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                   htmlFor="entry_1744637220">
              First Name
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                placeholder=""
                id="entry_1744637220"
                type="text"
                size="20"
                required
                maxLength="255"
                {...register("entry_1744637220", { required: true, maxLength: 255 })}
            />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                   htmlFor="entry_1063034035">
              Last Name
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                placeholder=""
                id="entry_1063034035"
                type="text" 
                size="20"
                required
                maxLength="255"
                {...register("entry_1063034035", { required: true, maxLength: 255 })}
            />
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-1/2 px-3">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                   htmlFor="entry_51356296">
              E-mail
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="entry_51356296"
                type="email"
                size="20"
                required
                maxLength="255"
                {...register("entry_51356296", { required: true, maxLength: 255 })}
            />
          </div>
          <div className="w-1/2 px-3">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                   htmlFor="entry_734667238">
              Phone
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="entry_734667238"
                type="tel"
                size="40"
                required
                maxLength="40"
                {...register("entry_734667238", { required: true, maxLength: 40 })}
            />
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="entry_1099629220">
              Title
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="entry_1099629220"
                type="text"
                size="20"
                maxLength="255"
                {...register("entry_1099629220", { required: false, maxLength: 255 })}
            />
          </div>
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="entry_1037349065">
              Company (or "Self")
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="entry_1037349065"
                type="text"
                size="20"
                maxLength="255"
                {...register("entry_1037349065", { required: false, maxLength: 255 })}
            />
          </div>

        </div>

        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                     htmlFor="entry_887410995">
              Role
            </label>
            <div className="relative">
              <select
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="entry_887410995"
                  type="text"
                  defaultValue=""
                  {...register("entry_887410995", { required: false })}
              >
                <option value="">N/A</option>
                <option value="Portfolio Manager">Portfolio Manager</option>
                <option value="Adviser">Adviser</option>
                <option value="Analyst">Analyst</option>
                <option value="Accounting & Finance">Accounting & Finance</option>
                <option value="Data Scientist">Data Scientist</option>
                <option value="Investor">Investor</option>
                <option value="Engineer or Developer">Engineer or Developer</option>
                <option value="Architect or Technologist">Architect or Technologist</option>
                <option value="Risk Management Professional">Risk Management Professional</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                </svg>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                     htmlFor="entry_509999726">
              Company Type
            </label>
            <div className="relative">
              <select
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="entry_509999726"
                  type="text"
                  defaultValue=""
                  {...register("entry_509999726", { required: false })}
              >
                <option value="">N/A</option>
                <option value="Banks & Trusts">Banks & Trusts</option>
                <option value="Advisory Firms">Advisory Firms</option>
                <option value="Family Offices">Family Offices</option>
                <option value="Funds">Funds</option>
                <option value="Pensions">Pensions</option>
                <option value="Endowments & Foundations">Endowments & Foundations</option>
                <option value="Brokerage Firms">Brokerage Firms</option>
                <option value="Sovereign Wealth Funds">Sovereign Wealth Funds</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                   htmlFor="entry_1907527093">
              Message
            </label>
            <textarea
                className=" no-resize appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-40 resize-none"
                id="entry_1907527093"
                maxLength="255"
                  {...register("entry_1907527093", { required: false, maxLength: 255 })}
            ></textarea>
          </div>
        </div>
        <input
          className="shadow bg-primary-darker hover:bg-teal-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 mb-8 px-4 rounded"
            type="submit" name="submit"/>
        <ErrorMessage errors={errors} name="apiError"
                      render={({ message }) =>
                          <div className="api-error" role="alert">
                              <p className="font-bold">Error submitting form</p>
                              <p>{message}</p>
                          </div>}/>
          </form>
      </section>
    </Layout>
  );
}

export default ContactPage;
